<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ actualYear }} &copy; Segasp Univalores.</div>
        <div class="col-sm-6">
          <div class="d-none d-md-flex align-items-center">
            <router-link :to="{ name: 'home' }">Termos de Uso</router-link>
            <div class="footer-divider"></div>
            <router-link :to="{ name: 'home' }"
              >Política de Privacidade</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter'
};
</script>

<style scoped>
.footer-divider {
  width: 1px;
  height: 0.9rem;
  margin: 0 1.5rem;

  background-color: var(--primary);
}
</style>
