<template>
  <div id="layout-wrapper" data-testid="main-wrapper">
    <NavBar />
    <SideBar :is-condensed="isMenuCondensed" />
    <ModalErrorRequest />
    <ModalSuccess onlyOk />

    <div class="main-content">
      <div class="page-content">
        <div id="container-fluid" class="container-fluid">
          <RouterView />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import router from '@/router';
import NavBar from '@/components/NavBar.vue';
import SideBar from '@/components/SideBar.vue';
import Footer from '@/components/TheFooter.vue';

import ModalErrorRequest from '@/components/ModalErrorRequest';
import ModalSuccess from '@/components/Modals/ModalSuccess';

export default {
  components: {
    NavBar,
    SideBar,
    Footer,
    ModalErrorRequest,
    ModalSuccess
  },
  data() {
    return {
      isMenuCondensed: false
    };
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable');

      if (window.screen.width >= 892) {
        router.afterEach(() => {
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
        });

        document.body.classList.toggle('vertical-collpsed');
      } else {
        router.afterEach(() => {
          document.body.classList.remove('sidebar-enable');
        });

        document.body.classList.remove('vertical-collpsed');
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    }
  },
  mounted() {
    this.$store.dispatch('auth/loadUser');
  }
};
</script>
