<template>
  <header id="page-topbar" data-testid="navbar-wrapper">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <router-link tag="a" :to="{ name: 'home' }" class="logo">
            <span class="logo-sm">
              <LogoSm />
            </span>
            <span class="logo-lg">
              <LogoLg />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          data-testid="toggle-menu"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          aria-label="Alternar menu"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars text-light" aria-hidden="true"></i>
        </button>

        <!-- CORRETOR -->
        <b-navbar-nav
          v-if="!showSpecialities"
          class="flex-row align-items-center d-none d-xl-flex"
        >
          <li class="nav-item">
            <a
              href="https://ead.unicarreiras.com.br/"
              target="_blank"
              class="text-light"
              >UNICARREIRAS</a
            >
          </li>
          <li class="nav-item ml-3">
            <div class="nav-link">
              <ButtonGroup
                text="Gerar Estudo"
                to="DiagnosisComplete"
                icon="bx bx-plus"
                sizeIcon="0.95"
                sideIcon="right"
                :click="() => {}"
              />
            </div>
          </li>
        </b-navbar-nav>
      </div>

      <div class="d-flex">
        <div
          class="d-none d-lg-flex align-items-center box-change-view mr-2"
          v-if="showChangeViewButtons"
        >
          <b-button
            class="mr-2"
            :variant="loggedAsBroker ? 'light' : 'outline-light'"
            @click="viewAsBroker()"
          >
            Consultor
          </b-button>

          <b-button
            :variant="loggedAsSpecialist ? 'light' : 'outline-light'"
            @click="viewAsSpecialist()"
          >
            Especialista
          </b-button>
        </div>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <span class="d-none d-xl-inline-block ml-1 mr-1">
              {{ user.nome }}
            </span>
            <i class="bx bx-chevron-down font-size-16 align-middle mr-1" />
          </template>

          <b-dropdown-item>
            <router-link tag="span" to="/contacts/profile">
              <i class="bx bx-user font-size-16 align-middle mr-1"></i>
              {{ $t('navbar.perfil') }}
            </router-link>
          </b-dropdown-item>

          <b-dropdown-item class="d-block" href="javascript: void(0);">
            <i class="bx bx-wallet font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.configurations') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <router-link
            :to="{ name: 'logout' }"
            class="dropdown-item text-danger"
          >
            <i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
            ></i>
            {{ $t('navbar.logout') }}
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import * as loggedAsTypes from '@/constants/loggedAsTypes';
import { ESPECIALISTA } from '@/constants/userRoles';

import LogoSm from '@/assets/companyLogo/logo-sm.vue';
import LogoLg from '@/assets/companyLogo/logo-lg.vue';

import ButtonGroup from '@/components/Buttons/ButtonGroup';

export default {
  name: 'NavBar',
  components: {
    LogoSm,
    LogoLg,
    ButtonGroup
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    viewAsBroker() {
      if (this.loggedAsBroker) return;

      this.$store.dispatch('auth/changeLoggedAs', {
        loggedAs: loggedAsTypes.BROKER
      });
    },
    viewAsSpecialist() {
      if (this.loggedAsSpecialist) return;

      this.$store.dispatch('auth/changeLoggedAs', {
        loggedAs: loggedAsTypes.SPECIALIST
      });
    }
  },
  computed: {
    ...mapState('auth', ['user', 'loggedAs']),
    loggedAsBroker() {
      return this.loggedAs === loggedAsTypes.BROKER;
    },
    loggedAsSpecialist() {
      return this.loggedAs === loggedAsTypes.SPECIALIST;
    },
    showSpecialities() {
      return this.$route.name === 'ActivityPanel';
    },
    showChangeViewButtons() {
      if (!this.user || !this.user.pessoa) return;

      if (this.user.pessoa.funcionario.funcionario_cargo_id === ESPECIALISTA)
        return false;

      return (
        this.user.pessoa.funcionario.funcionario_especialidades &&
        this.user.pessoa.funcionario.funcionario_especialidades.length > 0
      );
    }
  }
};
</script>

<style lang="scss">
.navbar-header .dropdown:not(.show) .header-item {
  color: #eff2f7;
}
</style>

<style lang="scss" scoped>
#page-topbar {
  background-color: #62254e;
}

#page-topbar .navbar-brand-box {
  text-align: center;
  background: #f9f9fb;
  padding: 0;
}

#page-topbar .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.box-change-view button.btn-outline-light {
  color: #eff2f7;

  &:hover {
    color: #212529;
  }
}
</style>
