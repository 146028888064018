<template>
  <div>
    <b-modal @hidden="resetModal" v-model="show">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        "
      >
        <img style="width: 13rem" src="@/assets/images/icons/success.png" />
        <p style="font-size: 25px; font-family: arial; color: var(--grey)">
          Bom trabalho !
        </p>

        <p style="font-size: 13px; font-family: arial; color: var(--grey)">
          {{ description }}
        </p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <div v-if="!onlyOk">
            <b-button
              style="width: 9rem; margin-right: 1rem"
              size="md"
              variant="danger"
              @click="cancel()"
            >
              CANCELAR
            </b-button>
            <b-button
              style="width: 9rem; margin-right: 5rem"
              size="md"
              variant="success"
              @click="ok()"
            >
              OK
            </b-button>
          </div>
          <div v-if="onlyOk">
            <b-button
              style="width: 10.5rem; margin-right: 9rem"
              size="md"
              variant="success"
              @click="ok()"
            >
              OK
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    onlyOk: Boolean
  },
  computed: {
    show() {
      return this.$store.state.modal.isOpenModalSuccess;
    }
  },
  data() {
    return {
      description: `Os dados foram enviados com sucesso! Acompanhe a resposta dos especialista e status da sua venda no PAINEL DE VENDAS. `
    };
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      return valid;
    },
    resetModal() {
      this.name = '';
      this.$store.state.modal.isOpenModalSuccess = false;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
    }
  }
};
</script>
