<template>
  <div id="sidebar-menu">
    <ul
      id="side-menu"
      class="metismenu list-unstyled"
      style="background-color: var(--dark-blue)"
    >
      <template v-for="item in menuItems">
        <li class="menu-title" v-if="item.isTitle" :key="item.id">
          <span>
            {{ item.label }}
          </span>
        </li>
        <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
            style="background-color: var(--dark-blue)"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span class="font-weight-bold">
              {{ item.label }}
            </span>
            <span
              :class="`badge badge-pill badge-${item.badge.variant} float-right`"
              v-if="item.badge"
            >
              {{ item.badge.text }}
            </span>
          </a>

          <router-link
            :to="item.link"
            v-if="!hasItems(item)"
            class="side-nav-link-ref"
            style="background-color: var(--dark-blue)"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span class="font-weight-bold">
              {{ item.label }}
            </span>
            <span
              :class="`badge badge-pill badge-${item.badge.variant} float-right`"
              v-if="item.badge"
            >
              {{ item.badge.text }}
            </span>
          </router-link>

          <ul
            v-if="hasItems(item)"
            class="sub-menu"
            aria-expanded="false"
            style="background-color: var(--dark-blue)"
          >
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                :to="subitem.link"
                v-if="!hasItems(subitem)"
                class="side-nav-link-ref"
                style="background-color: var(--dark-blue)"
                >{{ subitem.label }}</router-link
              >
              <a
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow font-weight-bold"
                href="javascript:void(0);"
                >{{ subitem.label }}</a
              >
              <ul
                v-if="hasItems(subitem)"
                class="sub-menu mm-collapse"
                aria-expanded="false"
              >
                <li
                  v-for="(subSubitem, index) of subitem.subItems"
                  :key="index"
                >
                  <router-link :to="subSubitem.link" class="side-nav-link-ref">
                    {{ subSubitem.label }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import MetisMenu from 'metismenujs/dist/metismenujs';

import { checkLoggedAs } from '@/features/auth/auth.helpers';
import { SPECIALIST } from '@/constants/loggedAsTypes';

export default {
  name: 'SideNav',
  computed: {
    menuItems() {
      const menuItems = [];

      if (!checkLoggedAs([SPECIALIST])) {
        menuItems.push({
          id: 2,
          label: 'GERAR ESTUDO',
          icon: 'bx-check-square',
          subItems: [
            {
              id: 3,
              label: 'ESTUDO COMPLETO',
              link: '/diagnosis/complete',
              parentId: 2
            }
          ]
        });
      }

      if (!checkLoggedAs([SPECIALIST])) {
        menuItems.push({
          id: 7,
          label: 'LISTA DE ATIVIDADES',
          icon: 'bx-list-ul',
          link: '/to-do-list'
        });
      }

      if (checkLoggedAs([SPECIALIST])) {
        menuItems.push({
          id: 8,
          label: 'CLIENTES',
          icon: 'bx-group',
          link: '/customers-specialist'
        });
      }

      return menuItems;
    }
  },
  mounted: function () {
    new MetisMenu('#side-menu');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    const paths = [];

    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    const itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      const parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('mm-active');
        const parent2 = parent.parentElement.closest('ul');
        if (parent2 && parent2.id !== 'side-menu') {
          parent2.classList.add('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('mm-active');
            const childAnchor = parent3.querySelector('.has-arrow');
            const childDropdown = parent3.querySelector('.has-dropdown');
            if (childAnchor) childAnchor.classList.add('mm-active');
            if (childDropdown) childDropdown.classList.add('mm-active');

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== 'side-menu') {
              parent4.classList.add('mm-show');
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== 'side-menu') {
                parent5.classList.add('mm-active');
                const childanchor = parent5.querySelector('.is-parent');
                if (childanchor && parent5.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    }
  }
};
</script>

<style>
#sidebar-menu {
  padding-top: 1.5rem;
}

#sidebar-menu ul li a {
  padding: 1rem 1.5rem;
}

#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.6rem 1.5rem 0.6rem 3.5rem;
}

#sidebar-menu ul li ul.sub-menu li a {
  font-weight: 700;
  font-size: 11px;
}
</style>
