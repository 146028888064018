<template>
  <b-button-group @click="click" class="container">
    <b-button
      :style="`${sideIcon !== 'left' ? styleOrder1 : styleOrder2}`"
      :to="{ name: 'DiagnosisComplete' }"
      variant="dark-primary"
      >{{ text }}</b-button
    >

    <b-button
      :style="`${sideIcon === 'left' ? styleOrder1 : styleOrder2}`"
      :to="{ name: `${to}` }"
      variant="dark-primary"
    >
      <i :class="`${icon}`" :style="`font-size: ${sizeIcon}rem`" />
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  props: {
    text: String,
    to: String,
    icon: String,
    sizeIcon: String,
    sideIcon: String,
    click: Function
  },
  data() {
    return {
      styleOrder1: `
        order: 1;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      `,
      styleOrder2: `
        order: 2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      `
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
}
</style>
