<template>
  <div>
    <b-modal @hidden="resetModal" v-model="show">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        "
      >
        <img style="width: 13rem" src="@/assets/images/icons/cancel.png" />
        <p style="font-size: 20px; font-family: arial; color: #767a8e">
          Ocorreu um erro, {{ message }}
        </p>
      </div>
      <template #modal-footer="{ ok }">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <b-button
            style="width: 7rem; margin-right: 11rem"
            size="md"
            variant="success"
            @click="ok()"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  computed: {
    show() {
      return this.$store.state.modal.isOpenModalErrorRequest;
    },
    message() {
      return this.$store.state.modal.errorMessageModal;
    }
  },
  data() {
    return {};
  },
  methods: {
    resetModal() {
      this.name = '';
      this.$store.state.modal.isOpenModalErrorRequest = false;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    }
  }
};
</script>
